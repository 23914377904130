/*=====================
-----------------------
--------IMPORTS--------
-----------------------
======================*/
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*=====================
-----------------------
-----GENERAL STYLES----
-----------------------
======================*/
html, body {
  height: 100%;
}

.break-words {
  word-break: break-all;
}

.btn-full {
  width: 100%;
}

.row {
  max-width: 100vw;
}

table a:visited {
  color: $ard-blue;
}

.panel-ard {
  > .panel-heading {
    color: #ffffff;
    background-color: $ard-blue;
    border-color: #d3e0e9;
  }

  .panel-body {
    &.scroll {
      height: 370px;
      overflow-y: scroll;

      &.with-footer {
        height: 313px;
      }
    }
  }

  .panel-footer {
    background-color: white;
    height: 57px;
    overflow: hidden;

    .pagination {
      margin: 0 0 0 0;
    }
  }
}

.ard-blue {
  color: $ard-blue;
}

.heading {
  h1 {
    margin-bottom: 22px;
  }

  .btn-add {
    margin-top: 26px;
  }
}

.btn-primary {
  background: $ard-blue;

  &:hover {
    background: $ard-light;
  }
}

.btn-panel {
  float: right;
  margin-top: -7px;
  margin-right: -11px;
}

.traffic-lights {
  label {
    height: 40px;
    min-width: 40px;
    color: white;

    &.green-light {
      background-color: lighten($brand-success, 20%);

      &:hover, &.active {
        color: white;
        background-color: $brand-success;
      }
    }

    &.amber-light {
      background-color: lighten($brand-warning, 20%);

      &:hover, &.active {
        color: white;
        background-color: $brand-warning;
      }
    }

    &.red-light {
      background-color: lighten($brand-danger, 20%);

      &:hover, &.active {
        color: white;
        background-color: $brand-danger;
      }
    }
  }
}

/*=====================
-----------------------
----LOADING SPINNER----
-----------------------
=====================*/

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*=====================
-----------------------
-------DASHBOARD-------
-----------------------
======================*/

@mixin dashboard-stat($num, $color) {
  .stat-wrapper:nth-child(#{$num}) {
    .dashboard-stat:first-child {
      height: 100px;
      margin-bottom: 20px;
      border: 2px solid $color;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;

      .stat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        float: left;
        height: 100%;
        background: $color;

        p {
          text-align: center;
          color: white;
          margin-bottom: 0;
          font-size: 2em;
        }
      }

      .stat-info {
        float: left;
        width: 70%;
        padding-left: 10%;

        .stat-num {
          font-size: 2em;
          font-weight: bold;
          margin: 0;
          color: $color;
        }

        .stat-text {
          font-size: 1.2em;
        }
      }
    }
  }
}

@include dashboard-stat(1, $brand-info)
@include dashboard-stat(2, $brand-warning)
@include dashboard-stat(3, $brand-danger)
@include dashboard-stat(4, $brand-success)

.dashboard-panel {
  height: 430px;
}

/*=====================
-----------------------
----FORM SUBMISSIONS---
-----------------------
======================*/
.submission {
  .answers {
    .section {
      h3 {
        border: 1px outset #AA9;
        padding: 14px;
        margin: 30px 0;
        text-align: center;
        background: $ard-blue;
        color: #ffffff;
      }

      .answer {
        .form-control[disabled] {
          cursor: auto;
        }

        input[type="radio"][disabled],
        input[type="radio"].disabled,
        input[type="checkbox"][disabled],
        input[type="checkbox"].disabled {
          cursor: auto;
        }

        img {
          max-height: 300px;

          &.signature {
            max-height: 100px;
          }
        }
      }
    }
  }
}

/*=====================
-----------------------
--------PROJECTS-------
-----------------------
======================*/

.btn-panel.upload-document {
  margin-right: 10px;
  border-color: #ffffff;
}

/*=====================
-----------------------
---RESPONSIVE TABLES---
-----------------------
======================*/

.table-responsive {
  min-height: 150px;
  display: block;
  overflow: scroll;

}

/*=====================
-----------------------
-----NOTIFICATIONS-----
-----------------------
======================*/

#notifications {
  .recipients {
    box-shadow: #dddddd -4px 4px 14px;
    border: 1px solid #dddddd;
    display: none;
    position: absolute;
    background: white;
    border-radius: 10px;
    padding: 10px;
  }
}

/*=====================
-----------------------
-----SIDE NAVBAR-----
-----------------------
======================*/

/*hamburger menu*/

.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: white;
  color: $ard-blue;

  .icon-bar {
    background-color: $ard-blue;
  }
}

#app {
  background-color: $ard-blue;
  min-height: 100vh;
}

#page-wrapper {
  padding-top: 20px;
  background-color: #f5f8fa;
  min-height: 100vh;
}

#page-wrapper2 {
  padding-top: 20px;
  background-color: #f5f8fa;
  min-height: 100vh;
}

.navbar-default {
  background-color: $ard-blue;
  overflow: visible;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: white;

}

.navbar-right {
  margin-right: 0;
}

.navbar-brand {
  padding: 0;
  overflow: hidden;
}

.navbar-right {
  margin-right: 0;
}

.sidebar .toggle-btn {
  display: none;
}

/*general*/

.sidebar ul,
.sidebar li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  cursor: pointer;
}

.sidebar ul :not(collapsed) .arrow:before,
.sidebar li :not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}

/*main links*/

sidebar li {
  padding-left: 0px;
  border-bottom: 1px solid;
}

.sidebar li a {
  text-decoration: none;
  color: #ffffff;
  background-color: $ard-blue;
}

.sidebar li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  background-color: #0072e3;
  color: white;
}

.sidebar ul li.active > a {
  background-color: #fff;
  color: #000;
}

/*sub menu links*/

sidebar .nav.nav-second-level li {
  padding-left: 0px;
  border-bottom: 1px solid;
}

.sidebar .nav.nav-second-level li a {
  text-decoration: none;
  color: #ffffff;
  background-color: #0072e3;
}

.sidebar .nav.nav-second-level li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}

.sidebar .nav.nav-second-level li a:hover, .sidebar .nav.nav-second-level li a:focus {
  text-decoration: none;
  background-color: $ard-blue;
  color: white;
}

.sidebar .nav.nav-second-level li a.active {
  //background-color: $ard-blue;
  color: white;
  font-weight: 800;
  font-style: italic;
}

/*Username form*/

#usernamelogout.dropdown-toggle {
  color: white;
}

.open #usernamelogout.dropdown-toggle {
  color: black;
  background-color: white;
}

@media (max-width: 768px) {
  .navbar-default {
    background-color: $ard-blue;
    padding: 0;
  }

  #usernamelogout.dropdown-toggle {
    color: white;
    padding-left: 0;
    padding-right: 0;
    text-align: right;
  }

  #userdropdownmenu.dropdown {
    float: right;
    margin-right: 0;
  }
}

div.visible-xs th, div.visible-xs td {
  cursor: pointer;

  a.card {
    width: 100%;
    display: block;
    text-decoration: none;
    color: black;
  }

  h3 {
    overflow: hidden;

    a.cardhead {
      color: $ard-blue;
      display: block;
      text-decoration: none;
    }
  }

  h4 {
    color: black;
    overflow: hidden;

    a.card {
      color: black;
    }

    a.cardvcat {
      color: black;
      display: block;
      text-decoration: none;

    }
  }

  h5 {
    a.card {
      font-size: 14pt;
    }
  }

}

.pagination li {
  a {
    color: $ard-blue;
  }
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  background-color: $ard-blue;
  border-color: $ard-blue;
}

@mixin project-stat($num, $color) {
  .stat-wrapper:nth-child(#{$num}) {
    .project-stat:first-child {
      height: 120px;
      margin-bottom: 20px;
      border: 2px solid $color;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;

      .stat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        float: left;
        height: 100%;
        background: $color;

        p {
          text-align: center;
          color: white;
          margin-bottom: 0;
          font-size: 2em;
        }
      }

      .stat-info {
        float: left;
        width: 70%;
        padding-left: 10%;

        .stat-num {
          font-size: 2em;
          font-weight: bold;
          margin: 0;
          color: $color;
        }

        .stat-text {
          font-size: 1.2em;
          color: $color;
        }
      }
    }
  }
}

@include project-stat(1, $ard-blue)
@include project-stat(2, $brand-info)

.general-stat {
  height: 120px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;

  .stat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    float: left;
    height: 100%;

    p {
      text-align: center;
      color: white;
      margin-bottom: 0;
      font-size: 2em;
    }
  }

  .stat-info {
    float: left;
    width: 70%;
    padding-left: 10%;

    .stat-num {
      font-size: 2em;
      font-weight: bold;
      margin: 0;

    }

    .stat-text {
      font-size: 1.2em;
    }
  }
}

.diary-stat {
  height: 120px;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;

  .stat-info {
    float: left;
    width: 100%;

    .stat-num {
      font-size: 2em;

      font-weight: bold;
      margin: 0;
    }

    .stat-text {
      font-size: 1.2em;

    }

    p {
      text-align: center;
      margin-bottom: 0;
      font-size: 2em;
    }
  }
}

/*Yes/No/N/a form field*/

.yes-no-na-field {
  .btn-default:disabled {
    border-color: $ard-blue;
    color: $ard-blue;
    opacity: 1;
  }

  .answer-selected:disabled {
    background-color: $ard-blue;
    color: white;
    opacity: 1;
  }
}

.heading {
  #logo {
    display: none;
  }
}

#timesheets-report {
  margin-bottom: 15px;
}

@media print {

  @page {
    border: none;
  }
  body {
    -webkit-print-color-adjust: exact !important;
    border:none;
  }

  #page-wrapper {
    width: 100%;
    margin: 0;
    padding:0;
    border: none;
  }

  .container-fluid {
    padding: 15px;
    border: none;
  }

  #wrapper {
    display: none;
  }

  .panel-default {
    border: none;
  }

  .sidebar {
    width: 0;
  }

  .answer.no-break, .signature {
    page-break-inside: avoid;
  }

  #section-overview {
    page-break-after: always;
  }

  #subtoproject {
    visibility: hidden;
  }
  .heading {
    #logo {
      display: block;
    }
  }

  .submission .answers .section h3 {
    border: 1px outset #AA9;
    padding: 14px;
    margin: 30px 0;
    text-align: center;
    background: #26336d !important;
    color: white !important;
  }

  .yes-no-na-field {
    .btn-default:disabled {
      border-color: $ard-blue;
      color: $ard-blue;
      opacity: 1;
    }

    .answer-selected:disabled {
      background: $ard-blue !important;
      -webkit-print-color-adjust: exact;
      color: white !important;
      opacity: 1;
    }

  }

  #submission-approval-form {
    display:none;
  }

  a[href]:after {
    visibility: hidden;
  }
}

.approval-badge {
  margin-right: 5px;
}

@media (min-width: 2000px) {
  .evidencethumbnail {
    height: 600px;
  }
}

@media (min-width: 1500px) and (max-width: 2000px) {
  .evidencethumbnail {
    height: 500px;
  }
}

@media (min-width: 992px) and (max-width: 1500px) {
  .evidencethumbnail {
    height: 400px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .evidencethumbnail {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .mobile-top-margin {
    margin-top: 15px;
  }
}